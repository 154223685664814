.container-promo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
  }
  .promo {
    .container-listpromo {
      margin-top: 30px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-end; /* Added justify-content */
      @media (max-width: 768px) {
        flex-direction: column;
        margin-top: -15px;
      }
      .containergambar {
        order: 1;
        width: 100%;
        text-align: right;
        @media (max-width: 768px) {
          margin-top: 25px;
          order: 1;
          width: 100%;
          text-align: center;
        }

        .penawarangambar {
          width: 600px;
          height: auto;
          align-self: flex-end;
          box-shadow: 0 0 10px 0;
          border-radius: 5px;
          @media (max-width: 768px) {
            width: 90%;
            height: 100%;
          }
        }
      }

      .containercontent {
        order: 2;
        width: 100%;
        margin-left: 100px;
        text-align: left;
        justify-content: center;
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
          margin-left: 25px;
          width: 90%;
          order: 2;
          margin-top: 30px;
        }
      }
      .judul-promo {
        width: 70%;
        font-weight: 500;
        font-size: 40px;
        line-height: 1em;
        color: #292929;
        font-family: "Poppins";
        @media (max-width: 768px) {
          width: 100%;
          font-size: 25px;
          margin-bottom: 17px;
        }
        .beda {
          font-weight: 500;
          font-size: 40px;
          line-height: 1em;
          color: darkgoldenrod;
          font-family: "Poppins";
        }
      }
      .disclaimer {
        margin-top: 25px;
        font-size: 13px;
      }
      .pointpenawaran {
        flex-direction: row;
        font-size: 30px;
        @media (max-width: 768px) {
          font-size: 25px;
          width: 100%;
        }
        .penawaranpoin {
          margin-top: 0px;
          color: #273444;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .button-wa-promo {
        padding-top: 15px;
        padding-bottom: 10px;
        .whatsapp {
          display: inline-block;
          padding: 10px 100px;
          cursor: pointer;
          border-radius: 2px;
          border: solid;
          border-width: 1px;
          background-color: #f4a40400; /* WhatsApp green */
          color: rgb(0, 0, 0);
          font-size: 16px;
          @media (max-width: 768px) {
            padding: 15px 80px;
          }
          &:hover {
            background-color: #1b252f;
            color: white;
            padding: 15px 110px;
            transition-duration: 0.3s;
            margin-bottom: -5px;
            margin-left: -10px;
            margin-top: -5px;
          }
        }
      }
    }
  }
}
