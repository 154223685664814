.vidoe {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
  }
  .judul {
    width: 35%;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.5em;
    color: #292929;
    font-family: "Poppins";
    @media (max-width: 768px) {
      width: 80%;
      font-size: 35px;
      margin-bottom: 17px;
      text-align: center;
      order: 2;
    }
  }
  .a {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
}
