.container-ruko {
  margin-top: 100px;
  .ruko {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(33, 33, 33);
    .header-ruko {
      .judul-ruko {
        color: white;
        text-align: center;
        font-size: 65px;
        margin-top: 50px;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 40px;
        }
      }
      .sub-judul-ruko {
        color: white;
        text-align: center;
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
    .galery-ruko {
      width: 100%;
      display: flex;
      justify-content: center;
      align-self: center;
      padding-bottom: 20px;
      @media (max-width: 768px) {
        width: 98%;
      }
      .kartu {
        width: 70%;
        justify-content: center;
        margin: auto;
        @media (max-width: 768px) {
          width: 100%;
        }
        .kartugambar1 {
          width: 95%;
          display: flex;
          margin: auto;
          justify-content: center;
          align-items: center;
          align-self: center;
          background-color: #d6d6d6;
          border-radius: 10px 10px;
          @media (max-width: 768px) {
            margin: 0px;
          }
        }
        .kartugambar2 {
          width: 100%;
          display: flex;
          margin: auto;
          justify-content: center;
          align-items: center;
          align-self: center;
          background-color: #d6d6d6;
          @media (max-width: 768px) {
            margin: 0px;
          }
        }
        .slick-slider {
          width: 100%;
          .slick-next::before,
          .slick-prev::before {
            font-family: "slick";
            font-size: 30px;
          }
          .slick-track {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
          }
          .slick-active {
            margin: 10px;
          }
          .slick-dots {
            position: absolute; /* Make dots absolute for positioning within slides */
            bottom: 10px; /* Adjust vertical positioning */
            left: 50%; /* Center dots horizontally */
            transform: translateX(-50%); /* Center dots precisely */
            z-index: 1; /* Ensure dots are above image content */
          }
          .slick-next {
            right: 25px;
          }
          .slick-prev {
            z-index: 1;
            left: 30px;
          }
          .slick-dots {
            li {
              margin: 0;
            }
          }
        }
      }
    }
    .container-card-ruko {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 100%;
      }
      .logo-business {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        width: 50%;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          width: 100%;
        }
        .logoruko {
          width: 50%;
          margin-bottom: -30px;
          @media (max-width: 768px) {
            width: 60%;
          }
        }
        .desk {
          width: 100%;
          text-align: center;
          color: aliceblue;
          @media (max-width: 768px) {
            width: 80%;
          }
        }
      }
      .cardruko {
        width: 100%;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        align-items: start;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .carousel-ruko-bukit {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          @media (max-width: 768px) {
            margin-left: 0px;
            margin-right: 0px;
          }
          .kartu {
            width: 450px;
            height: auto;
            margin-top: 10px;
            margin-left: 30px;
            margin-right: 30px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 10px 0;
            padding-bottom: 10px;
            hr {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 15px;
              width: 90%;
            }
            @media (max-width: 768px) {
              width: 95%;
              margin-bottom: 20px;
              margin-left: 0px;
              margin-right: 0px;
            }

            .kartugambar0 {
              width: 100%;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
            }
            .slick-slider {
              width: 100%;
              .slick-next::before,
              .slick-prev::before {
                font-family: "slick";
                font-size: 30px;
              }
              .slick-track {
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
              }

              .slick-dots {
                position: absolute; /* Make dots absolute for positioning within slides */
                bottom: 10px; /* Adjust vertical positioning */
                left: 50%; /* Center dots horizontally */
                transform: translateX(-50%); /* Center dots precisely */
                z-index: 1; /* Ensure dots are above image content */
              }
              .slick-next {
                right: 25px;
              }
              .slick-prev {
                z-index: 1;
                left: 25px;
              }
              .slick-dots {
                li {
                  margin: 0;
                }
              }
            }
            .container-title-card {
              width: 100%;
              margin-top: 10px;
              display: flex;

              .titlecard {
                width: 50%;
                padding: 10px;
                margin: 10px;
                display: flex;
                flex-direction: column;
                //align-items: center;
                //justify-content: center;
                .judul-rumah2 {
                  color: #b49c6c;
                  font-family: "Poppins";
                  font-size: 24px;
                  font-weight: 600;
                  @media (max-width: 768px) {
                    font-size: 20px;
                  }
                }
              }
              .cicilan {
                width: 50%;
                background-color: #b49c6c;
                padding: 10px;
                border-radius: 10px 0px 0 10px;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .angka {
                  font-family: "Poppins";
                  font-size: 48px;
                }
                .ket {
                  font-family: "Poppins";
                  font-size: 17px;
                }
              }
            }
            .gridspek {
              padding-left: 15px;
              display: flex;
              justify-content: center;
              align-items: center;

              span {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 5px;
                padding-right: 20px;
                @media (max-width: 768px) {
                  padding-right: 10px;
                }
              }
            }
            .containerwhatsapp {
              display: flex;
              justify-content: center;
              padding-top: 25px;
              .whatsapp {
                display: inline-block;
                padding: 10px 100px;
                cursor: pointer;
                border: none;
                border-radius: 5px;
                background-color: #b49c6c; /* WhatsApp green */
                color: white;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .gallery {
      width: 100%;
      margin-bottom: 50px;
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
    .surrounding-area {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 50px;
      .judul-surrounding {
        color: aliceblue;
        font-size: 56px;
        margin-top: 50px;
        font-family: "Poppins";
        margin-bottom: 20px;
        @media (max-width: 768px) {
          font-size: 36px;
        }
      }
      .surrounding {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        .logo-row {
          width: 100%;

          .foto {
            width: 80%;
          }
        }
      }
    }
  }
}
