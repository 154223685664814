.newlaunch {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  margin-bottom: 10%;
  padding-bottom: 20px;
  background-color: #292929;
  .container-newlaunch {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .judul-newlaunch {
      text-align: center;
      font-weight: 500;
      font-size: 40px;
      line-height: 1.5em;
      color: #fdfdfd;
      font-family: "Poppins";
      @media (max-width: 768px) {
        width: 80%;
        font-size: 25px;
        margin-bottom: 17px;
        text-align: center;
      }
    }
    .img-newlaunch {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 40%;
        border-radius: 10px;
        box-shadow: 0 0 10px 0;
        @media (max-width: 768px) {
          width: 90%;
        }
      }
    }
    .button-wa-promo {
      margin-top: 20px;
      padding-top: 15px;
      padding-bottom: 10px;
      .whatsapp {
        display: inline-block;
        padding: 10px 100px;
        cursor: pointer;
        border-radius: 2px;
        border: solid;
        border-width: 1px;
        background-color: #ffffff; /* WhatsApp green */
        color: rgb(0, 0, 0);
        font-size: 16px;
        @media (max-width: 768px) {
          padding: 10px 80px;
          font-size: 10px;
        }
        &:hover {
          background-color: #8a7f05;
          color: white;
        }
      }
    }
  }
}
