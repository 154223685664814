.container-lokasi {
  width: 100%;
  margin-bottom: 30px;
  .lokasi {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .location {
      width: 80%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 50px;
      @media (max-width: 768px) {
        width: 95%;
      }

      .judul-lokasi {
        font-size: 56px;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 500;
        color: #b49c6c;
        @media (max-width: 768px) {
          font-size: 50px;
        }
      }
      .img-lokasi {
        width: 100%;
        background-image: url("../../media/maps.webp"); /* Replace with your desktop image URL */
        background-size: cover;
        background-position: center;
        height: 62vh;
        @media (max-width: 768px) {
          background-image: url(../../media/maps-mobile.jpeg);
          height: 55vh;
        }
      }
    }
  }
}
