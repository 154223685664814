@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-fasilitas {
  width: 100%;
  margin-top: 150px;
  background-color: #dddddd78;
  .fasilitas {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .judul-fasilitas {
      font-family: "Poppins";
      font-size: 42px;
      font-weight: 600;
      margin: 30px;
      color: #b49c6c;
      @media (max-width: 768px) {
        text-align: center;
        font-size: 28px;
      }
    }
    .carousel-fasilitas {
      width: 80%;
      padding-bottom: 20px;
      @media (max-width: 768px) {
        width: 98%;
      }
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
      .list-fasilitas {
        width: 100%;
        display: flex;
        flex-direction: row;
        @media (max-width: 768px) {
          flex-direction: column;
          justify-content: center;
        }
        .list1 {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          padding-left: 10px;
          padding-right: 10px;
          @media (max-width: 768px) {
            justify-content: center;
          }
          .a1 {
            margin-top: 20px;
          }
          .judul-list {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 18px;
            padding-top: 5px;
            padding-bottom: 5px;
            color: #b49c6c;
          }
          .ket-list {
            font-size: 14px;
          }
        }
      }
    }
  }
}
