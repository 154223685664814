@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-about {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 10px 0;
  background-image: url(../../media/backgroundabout.webp);
  background-size: auto;
  background-color: rgb(21, 28, 35);
  box-shadow: inset 0 0 0 2000px rgba(22, 22, 22, 0.677);
  .about {
    width: 80%;
    margin: 50px;
    @media (max-width: 768px) {
      width: 90%;
    }
    .judul-about {
      text-align: center;
      font-family: "Poppins";
      font-size: 46px;
      font-weight: 500;
      color: white;
      @media (max-width: 768px) {
        font-size: 35px;
      }
    }
    .desk-about {
      text-align: center;
      font-family: "Poppins";
      font-size: 14px;
      color: white;
    }
  }
}
