.container-gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
  .judul-gallery {
    text-align: center;
    font-size: 56px;
    font-family: "Poppins";
    color: aliceblue;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
  .gallerylogo {
    width: 100%;
    .kartugambar01 {
      width: 80%;
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      align-self: center;
      @media (max-width: 768px) {
        margin: 0px;
      }
    }
    .slick-slider {
      width: 100%;
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }
      .slick-active {
        margin: 10px;
      }
      .slick-dots {
        position: absolute; /* Make dots absolute for positioning within slides */
        bottom: 10px; /* Adjust vertical positioning */
        left: 50%; /* Center dots horizontally */
        transform: translateX(-50%); /* Center dots precisely */
        z-index: 1; /* Ensure dots are above image content */
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 30px;
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }
    }
  }
}
