@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.rumah {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 70px;
  .judul-rumah1 {
    text-align: center;
    font-family: "Poppins";
    font-size: 66px;
    font-weight: 600;
    letter-spacing: 0.1em;
    color: #b49c6c;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
  .container-parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .container {
      width: 80%;
      display: flex;
      flex-direction: row;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .container-carousel-foto {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .kartu {
          width: 450px;
          height: auto;
          margin-top: 10px;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0 0 10px 0;
          padding-bottom: 10px;
          hr {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            width: 90%;
          }
          @media (max-width: 768px) {
            width: 120%;
            margin-bottom: 20px;
            margin-left: 0px;
          }

          .kartugambar1 {
            width: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          .slick-slider {
            width: 100%;
            .slick-next::before,
            .slick-prev::before {
              font-family: "slick";
              font-size: 30px;
            }
            .slick-track {
              display: flex;
              justify-content: center;
              align-items: center;
              align-self: center;
            }

            .slick-dots {
              position: absolute; /* Make dots absolute for positioning within slides */
              bottom: 10px; /* Adjust vertical positioning */
              left: 50%; /* Center dots horizontally */
              transform: translateX(-50%); /* Center dots precisely */
              z-index: 1; /* Ensure dots are above image content */
            }
            .slick-next {
              right: 25px;
            }
            .slick-prev {
              z-index: 1;
              left: 25px;
            }
            .slick-dots {
              li {
                margin: 0;
              }
            }
          }
          .container-title-card {
            width: 100%;
            margin-top: 10px;
            display: flex;

            .titlecard {
              width: 50%;
              padding: 10px;
              margin: 10px;
              display: flex;
              flex-direction: column;
              //align-items: center;
              //justify-content: center;
              .judul-rumah2 {
                color: #b49c6c;
                font-family: "Poppins";
                font-size: 24px;
                font-weight: 600;
                @media (max-width: 768px) {
                  font-size: 20px;
                }
              }
            }
            .cicilan {
              width: 50%;
              background-color: #b49c6c;
              padding: 10px;
              border-radius: 10px 0px 0 10px;
              color: white;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .angka {
                font-family: "Poppins";
                font-size: 48px;
              }
              .ket {
                font-family: "Poppins";
                font-size: 17px;
              }
            }
          }
          .gridspek {
            padding-left: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-left: 5px;
              padding-right: 20px;
              @media (max-width: 768px) {
                padding-right: 10px;
              }
            }
          }
          .containerwhatsapp {
            display: flex;
            justify-content: center;
            padding-top: 25px;
            .whatsapp {
              display: inline-block;
              padding: 10px 100px;
              cursor: pointer;
              border: none;
              border-radius: 5px;
              background-color: #b49c6c; /* WhatsApp green */
              color: white;
              font-size: 16px;
            }
          }
        }
      }
      .container-all-card {
        width: 100%;
        margin: 20px;
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
          margin: 0;
        }
        .container-card1 {
          width: 100%;
          flex-direction: row;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 768px) {
            flex-direction: column;
          }
          .card {
            width: 360px;
            height: auto;
            //margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 10px 0;
            padding-bottom: 10px;
            hr {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 15px;
              width: 90%;
            }
            @media (max-width: 768px) {
              width: 120%;
              margin-bottom: 20px;
              margin-left: 0px;
              margin-right: 0px;
              margin-top: 20px;
            }

            .kartugambar1 {
              width: 100%;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              background-color: #d6d6d6;
              @media (max-width: 768px) {
                margin: 0px;
              }
            }
            .slick-slider {
              width: 100%;
              .slick-next::before,
              .slick-prev::before {
                font-family: "slick";
                font-size: 30px;
              }
              .slick-dots {
                position: absolute; /* Make dots absolute for positioning within slides */
                bottom: 10px; /* Adjust vertical positioning */
                left: 50%; /* Center dots horizontally */
                transform: translateX(-50%); /* Center dots precisely */
                z-index: 1; /* Ensure dots are above image content */
              }
              .slick-next {
                right: 25px;
              }
              .slick-prev {
                z-index: 1;
                left: 25px;
              }
              .slick-dots {
                li {
                  margin: 0;
                }
              }
            }
            .container-title-card {
              width: 100%;
              //margin-top: 10px;
              display: flex;

              .titlecard {
                width: 100%;
                //padding: 10px;
                //margin: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: left;
                .judul-rumah {
                  color: #b49c6c;
                  font-family: "Poppins";
                  font-size: 30px;
                  font-weight: 600;
                }
              }
              .cicilan {
                width: 50%;
                background-color: #b49c6c;
                padding: 10px;
                border-radius: 10px 0px 0 10px;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .angka {
                  font-family: "Poppins";
                  font-size: 48px;
                }
                .ket {
                  font-family: "Poppins";
                  font-size: 17px;
                }
              }
            }
            .gridspek {
              padding-left: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 5px;
                padding-right: 20px;
                @media (max-width: 768px) {
                  padding-right: 10px;
                }
              }
            }
            .containerwhatsapp {
              display: flex;
              justify-content: center;
              padding-top: 25px;
              .whatsapp {
                display: inline-block;
                padding: 10px 100px;
                cursor: pointer;
                border: none;
                border-radius: 5px;
                background-color: #b49c6c; /* WhatsApp green */
                color: white;
                font-size: 16px;
              }
            }
          }
        }
        .container-card2 {
          width: 100%;
          flex-direction: row;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 768px) {
            flex-direction: column;
          }
          .card {
            width: 360px;
            height: auto;
            margin-top: 20px;
            margin-left: 10px;
            margin-right: 10px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 10px 0;
            padding-bottom: 10px;
            hr {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 15px;
              width: 90%;
            }
            @media (max-width: 768px) {
              width: 120%;
              margin-bottom: 20px;
              margin-left: 0px;
              margin-right: 0px;
            }

            .kartugambar1 {
              width: 100%;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
            }
            .slick-slider {
              width: 100%;
              .slick-next::before,
              .slick-prev::before {
                font-family: "slick";
                font-size: 30px;
              }
              .slick-track {
                display: flex;
                justify-content: center;
              }

              .slick-dots {
                position: absolute; /* Make dots absolute for positioning within slides */
                bottom: 10px; /* Adjust vertical positioning */
                left: 50%; /* Center dots horizontally */
                transform: translateX(-50%); /* Center dots precisely */
                z-index: 1; /* Ensure dots are above image content */
              }
              .slick-next {
                right: 25px;
              }
              .slick-prev {
                z-index: 1;
                left: 25px;
              }
              .slick-dots {
                li {
                  margin: 0;
                }
              }
            }
            .container-title-card {
              width: 100%;
              //margin-top: 10px;
              display: flex;

              .titlecard {
                width: 100%;
                // padding: 10px;
                // margin: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .judul-rumah {
                  color: #b49c6c;
                  font-family: "Poppins";
                  font-size: 30px;
                  font-weight: 600;
                }
              }
            }
            .gridspek {
              padding-left: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 5px;
                padding-right: 20px;
                @media (max-width: 768px) {
                  padding-right: 10px;
                }
              }
            }
            .containerwhatsapp {
              display: flex;
              justify-content: center;
              padding-top: 25px;
              .whatsapp {
                display: inline-block;
                padding: 10px 100px;
                cursor: pointer;
                border: none;
                border-radius: 5px;
                background-color: #b49c6c; /* WhatsApp green */
                color: white;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
