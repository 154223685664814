.container-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b2122;
  z-index: 1;
  box-shadow: 0 0 10px 2px;
  position: relative;
  .navbar {
    display: flex;
    width: 1322px;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 15px;
    }
    .navbar-logo {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 40px;
      @media (max-width: 768px) {
        justify-content: center;
        padding: 0px;
        align-items: center;
      }
      .img-logo {
        width: 20%;
        @media (max-width: 768px) {
          width: 30%;
          padding: 0px;
        }
      }
    }
    .button-navbar {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 40px;
      @media (max-width: 768px) {
        visibility: hidden;
        width: 0px;
        height: 0px;
        padding: 0px;
      }
      .whatsapp {
        display: inline-block;
        padding: 15px 40px;
        cursor: pointer;
        border-radius: 5px;
        border-width: 1px;
        background-color: #61ce70; /* WhatsApp green */
        color: rgb(255, 255, 255);
        font-size: 16px;
        @media (max-width: 768px) {
          padding: 15px 80px;
        }
        &:hover {
          background-color: #ace2b4;
          transition: 0.5s;
        }
      }
    }
  }
}
