.container-header {
  .header-dekstop {
    background-image: url("../../media/banner-dekstop.png"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 90vh;
    @media (max-width: 768px) {
      background-image: none;
      height: 100%;
    }
    .header-mobile {
      visibility: hidden;
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
      @media (max-width: 768px) {
        visibility: inherit;
      }
    }
  }
}
